<template>
 
  <div class="editor-wrap" v-if="Document">
    <div id="editorjs"></div>
    <div class="editor__buttons" v-if="this.$vuetify.breakpoint.lgAndUp && (Object.keys(Document).length && Object.keys(Document.data).length)">
        <v-btn 
          v-if="inEdit" 
          class='button__cancel' 
          @click="cancel()"
          elevation="0"
          color="#0A467E"
          dark
          tile
          height="44"
        >
          Выйти из редактирования
        </v-btn>
        <v-btn 
          v-if="!readOnly && !inEdit" 
          class='button__edit' 
          @click="edit()"
          elevation="0"
          color="#0A467E"
          dark
          tile
          height="44"
        >
          Редактировать
        </v-btn>
        <v-btn 
          v-if="inEdit && Object.keys(Document.data).length" 
          class='button__save'
          @click="save()"
          elevation="0"
          color="#0A467E"
          dark
          tile
          height="44"
        >
          Сохранить
        </v-btn>
    </div>
  </div>
</template>

<script>
// import Consumers from "./Consumers.vue";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import ImageTool from "@editorjs/image";
import Quote from "@editorjs/quote";
// import Table from '@editorjs/table';
import {mapGetters, mapActions} from "vuex"
export default {
  name: "Editor",

  data() {
    return {
      inEdit: false,
      errorDocument: {
        time: 1629209706671,
        blocks: [{ type: "header", data: { text: "Документ не найден. Или у вас нет прав доступа для просмотра", level: 4 } }],
        version: "2.21.0",
    },
    read: false,
    timeoutID: undefined
    };
  },

  watch: {
    inEdit: function () {
      window.editor.isReady
      .then(() =>
        { 
          // нужно попробовать использовать метод window.editor.readOnly.toggle()
          window.editor.readOnly.toggle()
          window.editor.destroy();
          this.editor();
          this.open();
          })
      }
  },

  computed: {
    ...mapGetters(['Document', 'User']),
    readOnly() {
      return !this.User.is_superuser;
    },
  },

  methods: {
    ...mapActions(['getDetailDocument', 'changePage', 'fetchAlerts']),
    edit() {
      this.inEdit = true;
    },
    cancel() {
      this.inEdit = false;
      this.getDetailDocument(this.$route.params.id);
    },
    save() {
      window.editor.save()
        .then((outputData) => {
          let form_data = new FormData();
          form_data.append("data", JSON.stringify(outputData))
          this.changePage({ body: form_data, id: this.$route.params.id});
        })
        .catch((error) => {
          this.fetchAlerts({
            type: 'error',
            alerts: ["Документ не был сохранен"]
          })
        });
        this.fetchAlerts({
            type: 'notify',
            alerts: ["Изменения сохранены"]
        })
    },
    async open() {
        await window.editor.isReady
        if(window.editor) {
          let document = this.Document.data;
          if (!document){
            document = this.errorDocument;
          }
          if (window.editor) {
            window.editor.render(document);  
          }
        }
    },
    
     editor: function () {
      const LinkTool = require('@editorjs/link');
      window.editor =  new EditorJS({

  i18n: {
    messages: {

      ui: {
        "blockTunes": {
          "toggler": {
            "Click to tune": "Нажмите, чтобы настроить",
            "or drag to move": "или перетащите",
          },
        },
        "inlineToolbar": {
          "converter": {
            "Convert to": "Конвертировать в"
          }
        },
        "toolbar": {
          "toolbox": {
            "Add": "Добавить"
          }
        }
      },

      toolNames: {
        "Color": 'Цвет',
        "Marker": 'Выделить',
        "Text": 'Текст',
        "linkTool": 'Ссылка',
        "raw": 'Сырой текст',
        "Image": 'Изображение',
        "Heading": "Заголовок",
        "List": "Список",
        "Warning": "Примечание",
        "Checklist": "Чеклист",
        "Quote": "Цитата",
        "Code": "Код",
        "Delimiter": "Разделитель",
        "Raw HTML": "HTML-фрагмент",
        "Table": "Таблица",
        "Link": "Ссылка",
        "Bold": "Полужирный",
        "Italic": "Курсив",
        "InlineCode": "Моноширинный",
      },
      tools: {
        "warning": { 
          "Title": "Название",
          "Message": "Сообщение",
        },
        "link": {
          "Add a link": "Вставьте ссылку"
        },
        "stub": {
          'The block can not be displayed correctly.': 'Блок не может быть отображен'
        }
      },
    }
  },
        config: {holder: "editorjs"},
        readOnly: !(this.$vuetify.breakpoint.lgAndUp && this.inEdit),

        tools: {
          header: {
            class: Header,
            inlineToolbar: true,
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            config: {
              quotePlaceholder: 'Текст',
              captionPlaceholder: 'Заголовок',
            },
          },
          // linkTool: {
          //   class: LinkTool,
          //   inlineToolbar: true,
          //   config: {
          //     endpoint: 'https://api.dobroagency.ru/api/link/', // Your backend endpoint for url data fetching
          //   },
          // },
          image: {
            class: ImageTool,
            config: {
              captionPlaceholder: 'Подпись к картинке',
              buttonContent: "Выберите изображение",
              endpoints: {
                byFile: `${process.env.VUE_APP_SOCIAL_HOST}api/handbook/image/`, // Your backend file uploader endpoint
                byUrl: `${process.env.VUE_APP_SOCIAL_HOST}api/handbook/image/link`, // Your endpoint that provides uploading by Url
              },
              additionalRequestHeaders: {
                  'Authorization': this.$axios.defaults.headers.common.Authorization,
              }
            },
          },
        },
      });
    },
  },

  async mounted() {
    if(this.$route.params.id) {    
      await this.getDetailDocument(this.$route.params.id)
      if(document.querySelector("#editorjs")) {
        this.editor();
        this.open();
      }
    }
    
    
    window.addEventListener("beforeunload", (e) => { 
        // if(this.inEdit) {
        //   this.save()
        // }
        // e.preventDefault()
    });
  },

  beforeDestroy() {
    // if(this.inEdit) {
    //   this.save()
    // }
  },

};
</script>

<style lang="scss">

/* #editorjs {
     background-color: gray; 
} */

.editor__buttons {
  padding-bottom: 30px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 66.666666%;
  background-color: #fff;
}

.ce-block__content {
    max-width: none;
}

.ce-toolbar {
    right: auto;
    left: -30px;
}

.ce-header {
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: bold; 
    color: #56565A;
    @at-root {
      h1#{&}{
        font-size: 36px;
        line-height: 38px;
      }

      h2#{&}{
        font-size: 28px;
        line-height: 30px;
      }

      h3#{&} {
        font-size: 18px;
        line-height: 22px;
      }

      h4#{&} {
        font-size: 16px;
        line-height: 20px;
      }
    }
}

.ce-paragraph {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;  
    color: #56565A;
}

.ce-paragraph a {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #0A467E;
}

.cdx-quote {
    display: flex;
    flex-direction: column-reverse;
}

.cdx-input {
    border-radius: 0;
    border: none;
    box-shadow: none;
    background-color: #F2F9FC;
}

.cdx-quote__caption {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #0A467E;
    padding: 30px 30px 15px;
}

.cdx-quote__text {
     padding: 0 30px 30px;
     min-height: auto;
}

.ce-toolbar__actions {
  position: static;
}

.ce-toolbar__plus {
  position: static;
  margin-top: 15px;
  transform: none !important;
  width: auto;
  height: auto;
}

.ce-toolbar .ce-toolbox {
  top: auto;
}

.codex-editor__redactor {
  padding-bottom: 100px !important;
}

.cdx-button {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.cdx-button svg {
  margin-right: 10px;
}
</style>
